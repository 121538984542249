<template>
    <div>
        <div class="form-row form-row_grid">
            <div class="form-col">
                <label>content alignment</label>
                <div class="radio-group mt-3">
                    <div class="radio">
                        <input type="radio" name="alignment" id="alignmentLeft" value="left" v-model="hero.alignment">
                        <label for="alignmentLeft">
                            <icon name="alignLeft" class="w-5 h-5 text-black"></icon>
                        </label>
                    </div>
                    <div class="radio">
                        <input type="radio" name="alignment" id="alignmentCenter" value="center" v-model="hero.alignment">
                        <label for="alignmentCenter">
                            <icon name="alignCenter" class="w-5 h-5 text-black"></icon>
                        </label>
                    </div>
                    <div class="radio">
                        <input type="radio" name="alignment" id="alignmentRight" value="right" v-model="hero.alignment">
                        <label for="alignmentRight">
                            <icon name="alignRight" class="w-5 h-5 text-black"></icon>
                        </label>
                    </div>
                </div>
                <!-- <toggle-buttons :options="alignments" v-model="hero.alignment"></toggle-buttons> -->
            </div>
            <div class="form-col">
                <label for="headline">headline</label>
                <text-input
                  id="headline"
                  v-model="hero.headline"
                  autocomplete="off"
                />
                <span v-if="hasError('hero.headline')" class="form-error">{{ showError('hero.headline') }}</span>
            </div>
        </div>
        <div class="form-row form-row_grid">
            <div class="form-col">
                <label>media width</label>
                <toggle-buttons :options="widths" v-model="hero.mediaWidth"></toggle-buttons>
            </div>
            <div class="form-col">
                <label for="subHeadline">sub-headline</label>
                 <text-input
                  id="subHeadline"
                  v-model="hero.subHeadline"
                  autocomplete="off"
                />
            </div>
        </div>
        <div class="form-row form-row_grid">
            <div class="form-col">
                <label>background image</label>
                <file-manager
                    :library="getLibraryImageFilesByBaseDirectory(['marketing', 'cms'])"
                    :libraryFilters="['marketing', 'cms']"
                    :main-directory="'cms'"
                    :max-upload-size="20"
                    :max-nb-of-files="1"
                    :noCropper="true"
                    v-model="hero.image"
                    @uploaded-to-s3="createWebsiteImage"
                ></file-manager>
                <span v-if="hasError('hero.image')" class="form-error">{{ showError('hero.image') }}</span>
            </div>
            <div class="form-col">
                <label for="buttonText">button text</label>
                <text-input
                  id="buttonText"
                  v-model="hero.buttonText"
                  autocomplete="off"
                />

                <label class="mt-4">button link</label>
                <url-input v-model="hero.buttonLink" :website-id="websiteId"></url-input>
            </div>
        </div>
        <modal-footer :primary="save"></modal-footer>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Widget from "@/mixins/Widget";
import Icon from "@/components/ui/Icon";
import UrlInput from "@/components/cms/UrlInput";
import ModalNavigation from "@/mixins/ModalNavigation";
import ToggleButtons from "@/components/ui/ToggleButtons";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import FileManager from "@/components/ui/filemanager/FileManager";
import TextInput from "@/components/ui/TextInput";

export default {
    mixins: [ModalNavigation, Widget],
    components: { ModalFooter, ToggleButtons, UrlInput, Icon, FileManager, TextInput },
    emits: ['save-page-widget'],
    computed: {
        ...mapGetters({
            getLibraryImageFilesByBaseDirectory: 'files/getLibraryImageFilesByBaseDirectory',
            getThumbnailUrlByFileId: 'files/getThumbnailUrlByFileId',
            getFileIdByThumbnailUrl: 'files/getFileIdByThumbnailUrl'
        })
    },
    data: () => {
        return {
            alignments: [
                {value: 'left', label: 'left'},
                {value: 'center', label: 'center'},
                {value: 'right', label: 'right'},
            ],
            widths: [
                {value: 'full-width', label: 'full-width'},
                {value: 'contained', label: 'contained'},
            ],
            hero: {
                alignment: 'left',
                headline: null,
                subHeadline: null,
                mediaWidth: 'full-width',
                image: [],
                buttonText: null,
                buttonLink: null
            },
            isProcessing: false
        }
    },
    methods: {
        save() {
            if (this.validator.passes()) {
                this.hero.image = this.getThumbnailUrlByFileId(this.hero.image[0]);
                this.isProcessing = true;
                this.$emit('save-page-widget', Object.assign({}, this.hero));
            }
        }
    },
    validations: {
        'hero.image': 'required',
    },
    mounted() {
        this.setActiveModalTitle('hero with image background');
    },
    created() {
        this.initValidator();

        if (this.values.length) {
            Object.assign(this.hero, {
                headline: this.getValue('headline'),
                subHeadline: this.getValue('subHeadline'),
                alignment: this.getValue('alignment'),
                mediaWidth: this.getValue('mediaWidth'),
                image: (this.getValue('image') ) ? [ this.getFileIdByThumbnailUrl( this.getValue('image') ) ] : [],
                buttonText: this.getValue('buttonText'),
                buttonLink: this.getValue('buttonLink'),
            });
        }
    }
}
</script>
